import * as React from 'react'
import Layout from '../components/layout'

const Products = () => {
    return (
        <Layout pageTitle="Przykładowe produkty">
            <p>
                „Dobra Markę” – profesjonalną szwalnię tętniącą życiem, kreatywnością i pasją pracujących w niej szwaczek - rozwijamy od 2018 roku. Nazwa firmy jest naszą wizytówką.
            </p>

        </Layout>
    )
}

export default Products